/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// Load self-hosted fonts
import 'typeface-montserrat';
// Load CSS
import './src/css/tailwind.css';

// Lazy load intersection observer polyfill
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
    // eslint-disable-next-line no-console
    console.log('Loaded IntersectionObserver polyfill');
  }
};
